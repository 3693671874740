<template>
  <div class="application-detail" :class="{skeleton: !state.application.loaded}">
    <div class="header">
      <div class="top">
        <div class="wrapper">
          <b class="title">{{ modalParams.application.formTitle }}</b>
          <div class="side">
            <span class="date">제출: {{ state.application.info.updateDate }}</span>
            <span class="ico" title="신청서 수정하기" @click="edit()"><i class="fa fa-pencil"></i></span>
            <span class="ico" title="신청서 삭제하기" @click="remove()"><i class="fa fa-trash"></i></span>
          </div>
        </div>
      </div>
      <div class="actions">
        <select class="form-control" @change="updateStatus($event)" v-model="state.application.status">
          <option value="unproven">심사 전</option>
          <option value="judge">심사 중</option>
          <option value="pass">통과</option>
          <option value="denied">부적합</option>
        </select>
        <div>
          <!-- 2024 우리동네 크라우드펀딩 지원사업(증권형) -->
          <button v-if="modalParams.application.formId === 93" class="btn btn-default" @click="copyToArchive">
            <span>아카이브로 이동</span>
          </button>
          <a :href="`/download/admin/forms/${modalParams.application.formId}/applications/${modalParams.application.id}/files`" target="_blank" rel="noopener noreferrer" class="btn btn-default">
            <i class="fa fa-download"></i>
            <span> 첨부파일 전체 다운로드</span>
          </a>
        </div>
      </div>
    </div>
    <div class="alert mt-4 p-3 text-center" v-if="state.application.info.canceled">이 신청서는 신청자에 의해 취소되었습니다.</div>
    <div class="content" v-for="(s, idx1) in state.application.info.steps" :key="idx1">
      <b class="title">{{ s.title }}</b>
      <table class="table table-bordered">
        <tr v-for="(i, idx2) in s.items" :key="idx2">
          <th v-if="i.type === 'checklist'">
            <div :title="i.content">{{ i.content }}</div>
          </th>
          <th v-else>
            <div :title="i.title">{{ i.title }}</div>
            <div class="desc">{{ i.description }}</div>
          </th>
          <td>
            <template v-if="i.answers.length">
              <div v-for="(a, idx3) in i.answers" :key="idx3" :class="{file: i.type === 'file'}">
                <template v-if="!['file', 'checklist', 'agreement', 'checkbox', 'select'].includes(i.type)">
                  <span :class="{empty: !a.value || (a.value && !a.value.trim())}">{{ (a.value && a.value.trim()) ? a.value.trim() : "(N/A)" }}</span>
                </template>
                <template v-else-if="['checklist', 'agreement', 'checkbox', 'select'].includes(i.type)">
                  <span v-if="i.options[i.options.findIndex(o => o.id.toString() === a.value)]">{{ i.options[i.options.findIndex(o => o.id.toString() === a.value)].title }}</span>
                </template>
                <template v-else>
                  <span class="file-name">{{ a.fileOriginName && a.fileOriginName.trim() }}</span>
                  <div class="actions">
                    <a :href="`/download/admin/forms/${modalParams.application.formId}/applications/${modalParams.application.id}/files/${a.value}`" target="_blank" rel="noopener noreferrer" class="btn btn-default" title="클릭하여 다운로드">
                      <i class="fa fa-download"></i>
                      <span> 다운로드</span>
                    </a>
                  </div>
                </template>
              </div>
            </template>
            <span v-else class="empty">(N/A)</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalApplicationDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});

      load();
    });

    const state = reactive({
      application: {
        loaded: false,
        status: null,
        info: {
          id: 0,
          canceled: false,
          constraintId: 0,
          createDate: "",
          finished: false,
          formId: 0,
          formTitle: "",
          keyAnswer: "",
          passportId: 0,
          saveDate: "",
          status: "",
          steps: [],
          updateDate: "",
        },
      }
    });

    const modalParams = store.getters.modalParams(component);

    const load = () => {
      state.application.loaded = false;

      for (let i = 0; i < 3; i += 1) {
        state.application.info.steps.push({
          title: "Wait a moment",
          items: [{
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          }, {
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          }, {
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          },]
        });
      }

      http.get(`/api/admin/forms/${modalParams.application.formId}/applications/${modalParams.application.id}`).then((res1) => {
        state.application.loaded = true;
        state.application.info = res1.data.body;
        state.application.status = modalParams.application.status;
      });
    };

    const updateStatus = (e) => {
      if (e.target.value === undefined) {
        return;
      }

      store.commit("confirm", {
        message: "신청서의 상태를 변경하시겠습니까?",
        subMessage: "[심사 중], [통과]로 변경 시 신청자에게 메시지 발송됨",
        allow() {
          const modal = store.getters.modals()[0];
          http.put(`/api/admin/forms/${modalParams.application.formId}/applications/${modalParams.application.id}`, {status: e.target.value}).then(() => {
            store.commit("setSwingMessage", "신청서의 상태를 변경하였습니다.");
            store.dispatch("callback", {modal});
          });
        },
        disallow() {
          state.application.status = modalParams.application.status;
        }
      });
    };

    const edit = () => {
      store.commit("confirm", {
        message: "해당 신청서를 수정하시겠습니까?",
        allowTxt: "수정",
        allow() {
          window.open(`/forms/${modalParams.application.formName}/applications/${modalParams.application.id}`, "_blank", "noopener,noreferrer");
        },
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "해당 신청서를 삭제하시겠습니까?",
        allowTxt: "삭제",
        allow() {
          http.delete(`/api/admin/forms/${modalParams.application.formId}/applications/${modalParams.application.id}`).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "신청서가 삭제되었습니다.");
              }
            });
          });
        },
      });
    };

    const copyToArchive = () => {
      store.commit("openModal", {
        name: "ArchiveApplicationMembers",
        params: {
          type: "copy",
          formId: modalParams.application.formId,
          applicationId: modalParams.application.id,
        },
        callback: `${component.name}.load`
      });
    };

    return {component, state, modalParams, edit, remove, updateStatus, copyToArchive};
  }
});
</script>

<style lang="scss" scoped>
.application-detail {
  background-color: #fff;
  min-height: $px300;
  padding: $px20;

  .header {
    .top {
      margin-bottom: $px21;

      > .wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        > .title {
          display: inline-block;
          font-size: $px18;

          b {
            font-weight: 600;
          }
        }

        .side {
          color: #aaa;

          .date {
            font-size: $px12;
            margin-right: $px10;
          }

          .ico {
            cursor: pointer;
            margin-right: $px5;
          }
        }
      }
    }

    > .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      select, .btn {
        font-size: $px12;
        display: inline-block;
        width: initial;
        height: $px43;
      }
    }
  }

  .alert {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    font-size: $px13;
  }

  .content {
    margin-top: $px20;
    margin-bottom: $px40;

    > .title {
      display: inline-block;
      margin-bottom: $px10;
    }

    table {
      table-layout: fixed;

      tr {
        th, td {
          padding: $px12 $px15;
        }

        th {
          background: #f7f7f7;
          width: $px170;
          max-width: $px170;

          > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.desc {
              font-size: $px10;
              white-space: pre-line;
              color: #aaa;
              font-weight: 400;
            }
          }
        }

        td {
          .empty {
            color: #ccc;
          }

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              white-space: pre-line;

              &.file-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .actions {
              .btn {
                font-size: $px10;
                padding: $px6 $px12;

                i {
                  display: inline-block;
                  margin-right: $px4;
                }
              }
            }

            &.file {
              &:not(:last-child) {
                margin-bottom: $px8;
              }
            }
          }

          &:not(:last-of-type) {
            margin-bottom: $px10;
          }
        }

        &:first-child {
          th, td {
            border-top: none;
          }
        }
      }
    }
  }

  &.skeleton {
    .header {
      .top .title, span {
        @include skeleton;
      }

      .actions {
        select, .btn {
          @include skeleton;
        }
      }
    }

    .content {
      b {
        @include skeleton;
      }

      .table > tr {
        th, td {
          div, .btn {
            @include skeleton;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content table tr th {
      width: $px100;
      max-width: $px100;
    }
  }

  @media(max-width: 767px) {
    .header {
      .top {
        flex-wrap: wrap;
      }
    }
  }
}
</style>